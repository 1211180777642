const awards = [
    {
        src: "./img/irdeto-first.png",
        alt: "first irdeto award",
        reason: "Team Impact",
    },
    {
        src: "./img/irdeto-second.png",
        alt: "second irdeto award",
        reason: "Innovation",
    }
];

export { awards };
