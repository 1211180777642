const ExperienceData = [
  {
    jobTitle: "Software Engineer Intern",
    company: "Qlik",
    companyWebsite: "https://www.qlik.com/",
    jobDesc: [
      "Familiarized myself with the challenges and approaches for designing interconnected, scalable systems.",
      "Took an iterative approach to development, dividing long-term goals into incremental milestones.",
      "Thrived in a fast-paced and collaborative environment.",
      "Took ownership and build of an end-to-end feature that spans multiple teams.",
      "Implementation of public APIs, especially REST-based, and application components.",
      "Familiarized myself with cloud technologies such as AWS, Azure, Docker, Kubernetes.",
      "Worked with and improved my knowledge of languages such as JavaScript (node.js) and Go",
    ],
    duration: "May 2018 - September 2018",
  },
  {
    jobTitle: "Software Engineer",
    companyWebsite: "https://www.linkedin.com/company/healthbrain/about/",
    company: "HealthBrain",
    jobDesc: [
      "Developed data models for applications, metadata tables, views and related database structures.",
      "Verified stability, interoperability, portability, security, or scalability of system architecture.",
      "Value prototyping and proving first",
      "Built dynamic, highly performant web applications using agile development methodology",
      "Worked closely with other engineers in a small, informal team environment",
      "Focused on creating end-user value",
      "Built interactive UI design and Restfully abstract the UI through a Node js backend",
    ],
    duration: "September 2018 - October 2019",
  },
  {
    jobTitle: "Software Engineer",
    company: "Irdeto",
    companyWebsite: "https://www.irdeto.com/",
    jobDesc: [
      "Tested and verified Irdeto's solutions to ensure high quality standards in all Irdeto products",
      "Developed continuous integration framework for testing software protection solutions",
      "Used leading edge systems – including mobile/portable devices",
      "Ran automated tests, collected and interpreted test results and modifying test framework code as required",
      "Inter-working with various component and application-level design teams",
      "Worked with a tight-knit team in designing, implementing and maintaining new features in the Web Video, Live Sporting Event, or P2P services product line",
      "Developed and deployed products in various cloud environments",
      "Worked in an agile fashion",
      "Video conferencing to work efficiently with a globally dispersed team",
      "Improved my knowledge of python leveraging tools like Selenium Headless WebDriver for automating, testing and scraping web applications",
      "Worked in cross functional teams that include professional services and technical operations as part of end to end product delivery and troubleshooting",
    ],
    duration: "January 2020 - August 2020",
  },
  {
    jobTitle: "Software Engineer",
    company: "CloutDesk",
    companyWebsite: "https://www.cloutdesk.com/",
    jobDesc: [
      "NodeJS / React / Redux stack",
      "Built and scaled PostgreSQL databases and GraphQL APIs",
      "Integrated and used 3rd party APIs - e.g. Stripe, Instagram, Auth0 etc.",
      "Prototyped, developed and delivered new end-to-end features to web and mobile apps",
      "Used Object-oriented development and design patterns",
      "Participated in product scoping, code review, production planning, and estimating",
      "Partnered with the CEO to ensure alignment between business strategy and product roadmap",
      "Developed elegant and scalable backend infrastructure solutions",
      "Guided the development team (local & remote) through ideation, problem-solving, and delivery of technical projects",
    ],
    duration: "August 2020 - January 2022",
  },
  {
    jobTitle: "Fullstack Software Engineer",
    company: "Properly",
    companyWebsite: "https://www.properly.ca/",
    jobDesc: [
      "Worked with Python 3 and other similar languages (Ruby, Node JavaScript) and databases (SQL and NoSQL) on the server side",
      "Worked with a modern version of JavaScript/CSS/HTML (es6, css4, html5) and modern UX frameworks (React, Angular) on the client side",
      "Contributed to effective participation in problem solving sessions with others: generating ideas, picking and building solutions, monitoring results, and fixing bugs",
      "Helped design small to medium systems including providing analysis of multiple options and collecting feedback",
      "Emphasized on user-centric features and long-term business goals through team involvement",
      "Participated in the technical design and implement product design iterations and experiments",
      "Technical contributor in a team with a variety of professionals with expertise in complementary areas such as UX frameworks, web APIs, and data systems",
      "Helped deliver early small scope experiments, complex and detail-oriented projects and incremental fixes to in-market products",
      "Full implementation responsibilities that included design participation, code development, automated test development, deployment, and code maintenance",
    ],
    duration: "January 2022 - Present",
  },
  {
    jobTitle: "Backend Software Engineer",
    company: "Extend",
    companyWebsite: "https://www.extend.com/",
    jobDesc: [
      "Collaborated on the design of and implement event-driven systems with AWS Serverless technologies",
      "Collaborated with members of the engineering, product and design teams to deliver enhancements compatible with the existing platform",
      "Delivered solutions that abstract the complexity of customers building integrations from various third party application platforms",
      "Encouraged innovation and foster an environment of continuous improvement",
      "Developed robust solutions to deliver key product features, such as APIs compatible with some of the world’s leading commerce solutions",
      "Developed and maintained a suite of automated tests to ensure the quality of the product",
      "Designed systems around microservice architecture patterns",
      "Built event driven systems and worked with AWS Lambda, DynamoDB, SQS, SNS, API Gateway and other cloud services",
    ],
    duration: "November 2022 - Present",
  },
];

export { ExperienceData };
